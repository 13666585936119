import Api              from "../../services/api";

export const types = {
    SETTING_UPDATE: 'SETTING_UPDATE',
    SETTING_LIST: 'SETTING_LIST',
    SETTING_LIST_ALL: 'SETTING_LIST_ALL',
};

export function settingAddSync(params, cb) {
    return async () => {
        await Api.put('/setting/add', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    cb && cb(0);
                    return 0;
                } else {
                    cb && cb(result.data);
                    return result.data;
                }
            });
    }
}

export function settingUpdateSync(params, cb) {
    return async () => {
        await Api.put('/setting/edit', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    cb && cb(0);
                    return 0;
                } else {
                    cb && cb(result.data);
                    return result.data;
                }
            });
    }
}

export function settingUpdate(params,cb) {
    return{
        type: types.SETTING_UPDATE,
        params,
        cb
    }
}

export function settingList(params, cb) {
    return{
        type: types.SETTING_LIST,
        params,
        cb
    }
}

export function settingListAll(params, cb) {
    return{
        type: types.SETTING_LIST_ALL,
        params,
        cb
    }
}