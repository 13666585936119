// action types
export const types = {
    GET_AUTH: "GET_AUTH",

    LIST: 'LIST',
    LIST_SUCCESS: 'LIST_SUCCESS',

    LIST_UPDATE: 'LIST_UPDATE',
    LIST_UPDATE_SUCCESS: 'LIST_UPDATE_SUCCESS',

    LIST_ESS: 'LIST_ESS',
    LIST_ESS_SUCCESS: 'LIST_ESS_SUCCESS',

    LIST_MSS: 'LIST_MSS',
    LIST_MSS_SUCCESS: 'LIST_MSS_SUCCESS',

    LIST_FORM_INFO: 'LIST_FORM_INFO',

    LIST_BIRTHDAY: 'LIST_BIRTHDAY',
    LIST_BIRTHDAY_SUCCESS: 'LIST_BIRTHDAY_SUCCESS',

    LIST_MENU: "LIST_MENU",
    SHOW_EOFFICE: "SHOW_EOFFICE",
    LIST_EOFFICE: "LIST_EOFFICE",
    LIST_EOFFICE_SUCCESS: "LIST_EOFFICE_SUCCESS",

    LIST_MODULE: "LIST_MODULE",

    SET_CURRENT_MODULE: "SET_CURRENT_MODULE",
    SET_CURRENT_MODULE_SUCCESS: "SET_CURRENT_MODULE_SUCCESS",
};

export function getAuth(params, cb) {
    return {
        type: types.GET_AUTH,
        params,
        cb
    }
}

export function getList(cb) {
    return {
        type: types.LIST,
        cb
    }
}

export function getMenuV2(cb) {
    return {
        type: types.LIST_MENU,
        cb
    }
}

export function updateBadgeNumber(params) {
    return {
        type: types.LIST_UPDATE,
        params
    }
}

export function getEss(params,cb) {
    return {
        type: types.LIST_ESS,
        params,
        cb
    }
}

export function getMss(params,cb) {
    return {
        type: types.LIST_MSS,
        params,
        cb
    }
}

export function getFormInfo(cb) {
    return {
        type: types.LIST_FORM_INFO,
        cb
    }
}

export function getListBirthday(cb) {
    return {
        type: types.LIST_BIRTHDAY,
        cb
    }
}

export function getFlagShowEOffice(cb) {
    return {
        type: types.SHOW_EOFFICE,
        cb
    }
}

export function getListEOffice(params, cb) {
    return {
        type: types.LIST_EOFFICE,
        params,
        cb
    }
}

export function getModule(params, cb) {
    return {
        type: types.LIST_MODULE,
        params,
        cb
    }
}

export function setCurrentModule(params, cb) {
    return {
        type: types.SET_CURRENT_MODULE,
        params,
        cb
    }
}