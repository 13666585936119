export const types = {
    APPROVALS_GET_CBO_STATUS: 'APPROVALS_GET_CBO_STATUS',
    APPROVALS_GET_CBO_STATUS_SUCCESS: 'APPROVALS_GET_CBO_STATUS_SUCCESS',
    APPROVALS_GET_GRID: 'APPROVALS_GET_GRID',
    APPROVALS_GET_GRID_SUCCESS: 'APPROVALS_GET_GRID_SUCCESS',
    APPROVALS_GET_ATTACHMENTS: 'APPROVALS_GET_ATTACHMENTS',
    APPROVALS_GET_ATTACHMENTS_SUCCESS: 'APPROVALS_GET_ATTACHMENTS_SUCCESS',
    APPROVALS_GET_HISTORYS: 'APPROVALS_GET_HISTORYS',
    APPROVALS_GET_HISTORYS_SUCCESS: 'APPROVALS_GET_HISTORYS_SUCCESS',
    APPROVALS_SAVE: 'APPROVALS_SAVE',
    APPROVALS_CANCEL: 'APPROVALS_CANCEL',
    APPROVALS_GET_FORM_INFO: 'APPROVALS_GET_FORM_INFO',
    APPROVALS_GET_CBO_TRANSTYPE: 'APPROVALS_GET_CBO_TRANSTYPE',
    APPROVALS_GET_CBO_TRANSTYPE_SUCCESS: 'APPROVALS_GET_CBO_TRANSTYPE_SUCCESS',
    APPROVALS_GET_REGISTER_TYPES: 'APPROVALS_GET_REGISTER_TYPES',
    APPROVALS_GET_REGISTER_TYPES_SUCCESS: 'APPROVALS_GET_REGISTER_TYPES_SUCCESS'
};

export function getRegisterTypes(params,cb) {
    return{
        type: types.APPROVALS_GET_REGISTER_TYPES,
        params,
        cb
    }
}

export function getCboStatus(params,cb) {
    return{
        type: types.APPROVALS_GET_CBO_STATUS,
        params,
        cb
    }
}

export function getCboApprovalTransType(cb) {
    return {
        type: types.APPROVALS_GET_CBO_TRANSTYPE,
        cb
    }
}

export function getAttachments(params,cb) {
    return{
        type: types.APPROVALS_GET_ATTACHMENTS,
        params,
        cb
    }
}

export function getHistorys(params,cb) {
    return{
        type: types.APPROVALS_GET_HISTORYS,
        params,
        cb
    }
}

export function getGridApprovals(params,cb) {
    return{
        type: types.APPROVALS_GET_GRID,
        params,
        cb
    }
}

export function saveApprovals(params,cb) {
    return{
        type: types.APPROVALS_SAVE,
        params,
        cb
    }
}

export function cancelApprovals(params,cb) {
    return{
        type: types.APPROVALS_CANCEL,
        params,
        cb
    }
}

export function getFormInfo(params,cb) {
    return{
        type: types.APPROVALS_GET_FORM_INFO,
        params,
        cb
    }
}



