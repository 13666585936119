export const types = {
    REPORT_GET_FORMINFO: 'REPORT_GET_FORMINFO',
    REPORT_SAVE_ATTACHMENT: 'REPORT_SAVE_ATTACHMENT',
    REPORT_SAVE_VOUCHER: 'REPORT_SAVE_VOUCHER',
};

export function getFormInfo(params,cb) {
    return{
        type: types.REPORT_GET_FORMINFO,
        params,
        cb
    }
}

export function saveAttachment(params,cb) {
    return{
        type: types.REPORT_SAVE_ATTACHMENT,
        params,
        cb
    }
}

export function saveVouchers(params,cb) {
    return{
        type: types.REPORT_SAVE_VOUCHER,
        params,
        cb
    }
}

