export const types = {
    HISTORY_SEARCH: 'HISTORY_SEARCH',
    HISTORY_SEARCH_SUCCESS: 'HISTORY_SEARCH_SUCCESS',
    HISTORY_DELETE: 'HISTORY_DELETE',
    HISTORY_DELETE_SUCCESS: 'HISTORY_DELETE_SUCCESS'
};

export function historySearch(params, cb) {
    return {
        type: types.HISTORY_SEARCH,
        params,
        cb
    }
}
export function historyDelete(params, cb) {
    return {
        type: types.HISTORY_DELETE,
        params,
        cb
    }
}
