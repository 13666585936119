import { types } from "./history_actions";

const initialState = {
    historySearch: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.HISTORY_DELETE_SUCCESS:
            return {
                ...state,
                historySearch: action.data
            };
        default:
            return state;
    }
}