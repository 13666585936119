/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/31/2019
 * @Example
 */

import Api from '../../services/api';
import {cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "../../redux/reports/report_actions";
import {delay} from "redux-saga";
import Config from "../../config";

export default function ReportsSagas() {
    return [
        watchGetFormInfo(),
        watchSaveVouchers(),
        watchSaveAttachment(),
    ];
}

export function* getFormInfo(data) {
    try {
        yield delay(300);
        let URL = Config.getSetting('REPORT_API_URL');
        URL = URL ? URL + '/w17f2500/get-form-info' : '/w17f2500/get-form-info';
        const response = yield Api.put(URL, data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get form info error');
    }

}

export function* watchGetFormInfo() {
    while (true){
        const watcher = yield takeLatest(types.REPORT_GET_FORMINFO, getFormInfo);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* saveAttachment(data) {
    try {
        yield delay(300);
        let URL = Config.getSetting('REPORT_API_URL');
        URL = URL ? URL + '/w17f2500/save-attachment' : '/w17f2500/save-attachment';
        const response = yield Api.put(URL, data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save attachment error');
    }

}

export function* watchSaveAttachment() {
    while (true){
        const watcher = yield takeLatest(types.REPORT_SAVE_ATTACHMENT, saveAttachment);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* saveVouchers(data) {
    try {
        yield delay(300);
        let URL = Config.getSetting('REPORT_API_URL');
        URL = URL ? URL + '/w17f2500/save-voucher-info' : '/w17f2500/save-voucher-info';
        const response = yield Api.put(URL, data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save vouchers error');
    }

}

export function* watchSaveVouchers() {
    while (true){
        const watcher = yield takeLatest(types.REPORT_SAVE_VOUCHER, saveVouchers);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

