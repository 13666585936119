// action types
export const types = {
    LOGIN: "LOGIN",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    INFO_USER: "INFO_USER",
    INFO_USER_SUCCESS: "INFO_USER_SUCCESS",
    USER_CHANGE_PASSWORD: "USER_CHANGE_PASSWORD",
    USER_CHANGE_PASSWORD_SUCCESS: "USER_CHANGE_PASSWORD_SUCCESS",
    USER_FORGOT_PASSWORD: "USER_FORGOT_PASSWORD",
    USER_FORGOT_PASSWORD_SUCCESS: "USER_FORGOT_PASSWORD_SUCCESS",
    CHECK_SECURITY_CODE: "CHECK_SECURITY_CODE",
    CHECK_SECURITY_CODE_SUCCESS: "CHECK_SECURITY_CODE_SUCCESS",
    USER_RESET_PASSWORD: "USER_RESET_PASSWORD",
    USER_RESET_PASSWORD_SUCCESS: "USER_RESET_PASSWORD_SUCCESS",
};

export function login(params, cb) {
    return {
        type: types.LOGIN,
        params,
        cb,
    };
}

export function getInfoUser(params, cb) {
    return {
        type: types.INFO_USER,
        params,
        cb,
    };
}

export function changePassword(params, cb) {
    return {
        type: types.USER_CHANGE_PASSWORD,
        params,
        cb,
    };
}

export function forgotPassword(params, cb) {
    return {
        type: types.USER_FORGOT_PASSWORD,
        params,
        cb,
    };
}

export function checkSecurityCode(params, cb) {
    return {
        type: types.CHECK_SECURITY_CODE,
        params,
        cb,
    };
}

export function resetPassword(params, cb) {
    return {
        type: types.USER_RESET_PASSWORD,
        params,
        cb,
    };
}
