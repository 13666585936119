/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/18/2019
 * @Example 
 */

import Api from '../../services/api';

export const types = {
    FILE_REMOVE: 'FILE_REMOVE',
};

export async function uploadCDN_sync(data, mode, tokenCDN) {
    return new Promise((resolve) => {
        const body = new FormData();
        if(mode==='multiple'){
            for(let i=0;i<data.length;i++)
                body.append('files', data[i]);
        }
        else{
            body.append('files', data);
        }
        Api.putCDN('/file/upload',body, tokenCDN)
            .then(result => {
                return resolve(result);
            });
    } );
}

export function removeFileCDN(params, tokenCDN,cb) {
    return {
        type: types.FILE_REMOVE,
        params,
        tokenCDN,
        cb,
    }
}