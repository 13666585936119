import {types} from "./home_actions";

const initialState = {
    login: null,
    lisBirthday: null,
    getListEOffice: [],
    currentModule: null,
    badgeNumber: null,
};

//Nhan action type tu Put va luu data vao store
export default function (state = initialState, action = {}) {
    switch (action.type){
        case types.LOGIN_SUCCESS:
            return{
                ...state,
                login: action.payload
            };
        case types.LIST_BIRTHDAY_SUCCESS:
            return{
                ...state,
                lisBirthday: action.payload
            };
        case types.LIST_EOFFICE_SUCCESS:
            return{
                ...state,
                getListEOffice: action.payload
            };
        case types.SET_CURRENT_MODULE_SUCCESS:
            return{
                ...state,
                currentModule: action.payload
            };
        case types.LIST_SUCCESS:
            return{
                ...state,
                badgeNumber: action.payload
            };
        case types.LIST_UPDATE_SUCCESS:
            return{
                ...state,
                badgeNumber: action.payload
            };
        default:
            return state;
    }
}
