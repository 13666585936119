import { all } from "redux-saga/effects";

import {
    watchLoading,
    watchUploadFile,
    watchLogin,
    watchGetTokenLogin,
    watchChangeThemes,
    watchUpdateFilters,
    watchGetThemes,
    watchRemoveDevice,
    watchAddDevice,
    watchUpdateBadge,
    watchGetBadge,
    watchListNoty,
    watchUpdateStatusNoty,
    watchListUser,
    watchCheckToken,
    watchGetListReminderMaster,
    watchGetListReminderDetails,
    watchIgnoreReminder,
} from "../redux/main/main_sagas";

import GeneralSagas from "../redux/general/general_sagas";
import W00F0010Sagas from "../redux/W0X/W00F0010/W00F0010_sagas";
import HistorySagas from "../redux/history/history_sagas";
import SettingSagas from "../redux/settings/settings_sagas";

import { watchGetCombo, watchGetList } from "../redux/W75/W75F4090/W75F4090_sagas";

import HomeSagas from "../redux/home/home_sagas";
//User
import UserSagas from "../redux/user/user_sagas";

import ReportsSagas from "../redux/reports/report_sagas";
import W77F1000Sagas from "../redux/W77/W77F1000/W77F1000_sagas";
import W77F1001Sagas from "../redux/W77/W77F1001/W77F1001_sagas";
import W75F2000Sagas from "../redux/W75/W75F2000/W75F2000_sagas";
import W75F2005Sagas from "../redux/W75/W75F2005/W75F2005_sagas";
import W75F2008Sagas from "../redux/W75/W75F2008/W75F2008_sagas";
import W75F2010Sagas from "../redux/W75/W75F2010/W75F2010_sagas";
import W29F2005Sagas from "../redux/W2X/W29F2005/W29F2005_sagas";
import W21F2010Sagas from "../redux/W2X/W21F2010/W21F2010_sagas";
import W29F2011Sagas from "../redux/W2X/W29F2011/W29F2011_sagas";
import W29F2025Sagas from "../redux/W2X/W29F2025/W29F2025_sagas";
import W25F2033Sagas from "../redux/W2X/W25F2033/W25F2033_sagas";
import W25F2012Sagas from "../redux/W2X/W25F2012/W25F2012_sagas";
import W25F2023Sagas from "../redux/W2X/W25F2023/W25F2023_sagas";
import W29F2030Sagas from "../redux/W2X/W29F2030/W29F2030_sagas";
import W29F2050Sagas from "../redux/W2X/W29F2050/W29F2050_sagas";
import W29F2056Sagas from "../redux/W2X/W29F2056/W29F2056_sagas";
import W29F2055Sagas from "../redux/W2X/W29F2055/W29F2055_sagas";
import W29F2070Sagas from "../redux/W2X/W29F2070/W29F2070_sagas";

import W29F2016Sagas from "../redux/W2X/W29F2016/W29F2016_sagas";
import W29F2017Sagas from "../redux/W2X/W29F2017/W29F2017_sagas";
//W09
import W09F0001Sagas from "../redux/W0X/W09F0001/W09F0001_sagas";
import W09F2000Sagas from "../redux/W0X/W09F2000/W09F2000_sagas";
import W09F2005Sagas from "../redux/W0X/W09F2005/W09F2005_sagas";
import W09F2060Sagas from "../redux/W0X/W09F2060/W09F2060_sagas";
import W09F2071Sagas from "../redux/W0X/W09F2071/W09F2071_sagas";
import W09F2092Sagas from "../redux/W0X/W09F2092/W09F2092_sagas";
import W09F2080Sagas from "../redux/W0X/W09F2080/W09F2080_sagas";
import W09F6000Sagas from "../redux/W0X/W09F6000/W09F6000_sagas";
import W09F9000Sagas from "../redux/W0X/W09F9000/W09F9000_sagas";
import W09F2152Sagas from "../redux/W0X/W09F2152/W09F2152_sagas";
import W09F2031Sagas from "../redux/W0X/W09F2031/W09F2031_sagas";
import W09F2081Sagas from "../redux/W0X/W09F2081/W09F2081_sagas";
import W09F2030Sagas from "../redux/W0X/W09F2030/W09F2030_sagas";
import W09F2032Sagas from "../redux/W0X/W09F2032/W09F2032_sagas";
import W09F4000Sagas from "../redux/W0X/W09F4000/W09F4000_sagas";
import W09F2205Sagas from "../redux/W0X/W09F2205/W09F2205_sagas";
import W09F2215Sagas from "../redux/W0X/W09F2215/W09F2215_sagas";
import W09F2225Sagas from "../redux/W0X/W09F2225/W09F2225_sagas";
import W09F2210Sagas from "../redux/W0X/W09F2210/W09F2210_sagas";
import W09F2200Sagas from "../redux/W0X/W09F2200/W09F2200_sagas";
import W09F2001Sagas from "../redux/W0X/W09F2001/W09F2001_sagas";
import W09F2270Sagas from "../redux/W0X/W09F2270/W09F2270_sagas";
import W09F2280Sagas from "../redux/W0X/W09F2280/W09F2280_sagas";
import W09F2281Sagas from "../redux/W0X/W09F2281/W09F2281_sagas";
import W09F2290Sagas from "../redux/W0X/W09F2290/W09F2290_sagas";
import W09F2291Sagas from "../redux/W0X/W09F2291/W09F2291_sagas";
import W09F2292Sagas from "../redux/W0X/W09F2292/W09F2292_sagas";
import W09F2310Sagas from "../redux/W0X/W09F2310/W09F2310_sagas";
import W09F1120Sagas from "../redux/W0X/W09F1120/W09F1120_sagas";
import W09F1121Sagas from "../redux/W0X/W09F1121/W09F1121_sagas";

//W29
import W29F2006Sagas from "../redux/W2X/W29F2006/W29F2006_sagas";
import W29F3000Sagas from "../redux/W2X/W29F3000/W29F3000_sagas";
import W29F2072Sagas from "../redux/W2X/W29F2072/W29F2072_sagas";
import W29F2085Sagas from "../redux/W2X/W29F2085/W29F2085_sagas";
import W29F2086Sagas from "../redux/W2X/W29F2086/W29F2086_sagas";
import W29F2038Sagas from "../redux/W2X/W29F2038/W29F2038_sagas";
import W29F2061Sagas from "../redux/W2X/W29F2061/W29F2061_sagas";
import W29F2300Sagas from "../redux/W2X/W29F2300/W29F2300_sagas";
import W29F2305Sagas from "../redux/W2X/W29F2305/W29F2305_sagas";
import W29F2120Sagas from "../redux/W2X/W29F2120/W29F2120_sagas";

import W29F2306Sagas from "../redux/W2X/W29F2306/W29F2306_sagas";
import W29F2060Sagas from "../redux/W2X/W29F2060/W29F2060_sagas";

import AdminSagas from "../redux/admin/admin_sagas";
import ApprovalSagas from "../redux/approvals/approvals_sagas";
import FileSagas from "../redux/files/file_sagas";
import W09F2070Sagas from "../redux/W0X/W09F2070/W09F2070_sagas";
import W09F1501Sagas from "../redux/W0X/W09F1501/W09F1501_sagas";
import W09F1500Sagas from "../redux/W0X/W09F1500/W09F1500_sagas";
import W09F2010Sagas from "../redux/W0X/W09F2010/W09F2010_sagas";
import W09F2020Sagas from "../redux/W0X/W09F2020/W09F2020_sagas";
import W09F2021Sagas from "../redux/W0X/W09F2021/W09F2021_sagas";
import W09F2023Sagas from "../redux/W0X/W09F2023/W09F2023_sagas";
import W09F2230Sagas from "../redux/W0X/W09F2230/W09F2230_sagas";
import W13F1003Sagas from '../redux/W1X/W13F1003/W13F1003_sagas';
import W21F2005Sagas from "../redux/W2X/W21F2005/W21F2005_sagas";
import W39F1011Sagas from "../redux/W3X/W39F1011/W39F1011_sagas";
import W38F2000Sagas from "../redux/W3X/W38F2000/W38F2000_sagas";
import W38F2001Sagas from "../redux/W3X/W38F2001/W38F2001_sagas";
import W51F1001Sagas from "../redux/W5X/W51F1001/W51F1001_sagas";
import W25F2002Sagas from "../redux/W2X/W25F2002/W25F2002_sagas";
import W39F1010Sagas from "../redux/W3X/W39F1010/W39F1010_sagas";
import W39F1012Sagas from "../redux/W3X/W39F1012/W39F1012_sagas";

import W39F1000Sagas from "../redux/W3X/W39F1000/W39F1000_sagas";
import W39F1005Sagas from "../redux/W3X/W39F1005/W39F1005_sagas";
import W39F3020Sagas from "../redux/W3X/W39F3020/W39F3020_sagas";
import W39F3025Sagas from "../redux/W3X/W39F3025/W39F3025_sagas";
import W39F3030Sagas from "../redux/W3X/W39F3030/W39F3030_sagas";

//W25
import W25F2000Sagas from "../redux/W2X/W25F2000/W25F2000_sagas";
import W25F2010Sagas from "../redux/W2X/W25F2010/W25F2010_sagas";
import W25F2015Sagas from "../redux/W2X/W25F2015/W25F2015_sagas";
import W25F2018Sagas from "../redux/W2X/W25F2018/W25F2018_sagas";
import W25F2041Sagas from "../redux/W2X/W25F2041/W25F2041_sagas";

import W25F2040Sagas from "../redux/W2X/W25F2040/W25F2040_sagas";
import W25F4000Sagas from "../redux/W2X/W25F4000/W25F4000_sagas";

import W29F2010Sagas from "../redux/W2X/W29F2010/W29F2010_sagas";
import W29F2012Sagas from "../redux/W2X/W29F2012/W29F2012_sagas";
import W29F2018Sagas from "../redux/W2X/W29F2018/W29F2018_sagas";
import W29F2040Sagas from "../redux/W2X/W29F2040/W29F2040_sagas";
import W29F2045Sagas from "../redux/W2X/W29F2045/W29F2045_sagas";
import W29F2100Sagas from "../redux/W2X/W29F2100/W29F2100_sagas";
import W29F2065Sagas from "../redux/W2X/W29F2065/W29F2065_sagas";
import W29F2080Sagas from "../redux/W2X/W29F2080/W29F2080_sagas";
import W29F2081Sagas from "../redux/W2X/W29F2081/W29F2081_sagas";
import W29F2068Sagas from "../redux/W2X/W29F2068/W29F2068_sagas";
import W29F2309Sagas from "../redux/W2X/W29F2309/W29F2309_sagas";
//W51
import { watchGetGridEquipments } from "../redux/W51/W51F1002/W51F1002_sagas";
import W29F2000Sagas from "../redux/W2X/W29F2000/W29F2000_sagas";
import W39F2000Sagas from "../redux/W3X/W39F2000/W39F2000_sagas";
import W39F2001Sagas from "../redux/W3X/W39F2001/W39F2001_sagas";
import W51F1100Sagas from "../redux/W51/W51F1100/W51F1100_sagas";
import W51F1101Sagas from "../redux/W51/W51F1101/W51F1101_sagas";
import W51F2201Sagas from '../redux/W5X/W51F2201/W51F2201_sagas';
import W51F2200Sagas from "../redux/W5X/W51F2200/W51F2200_sagas";
import W39F2035Sagas from "../redux/W3X/W39F2035/W39F2035_sagas";

//W39
import W39F1020Sagas from "../redux/W3X/W39F1020/W39F1020_sagas";
import W39F1025Sagas from "../redux/W3X/W39F1025/W39F1025_sagas";
import W39F1031Sagas from "../redux/W3X/W39F1031/W31F1031_sagas";
import W39F3010Sagas from "../redux/W3X/W39F3010/W39F3010_sagas";
import W39F3011Sagas from "../redux/W3X/W39F3011/W39F3011_sagas";
import W39F3001Sagas from "../redux/W3X/W39F3001/W39F3001_sagas";
import W39F3000Sagas from "../redux/W3X/W39F3000/W39F3000_sagas";
import W39F3005Sagas from "../redux/W3X/W39F3005/W39F3005_sagas";
import W39F3002Sagas from "../redux/W3X/W39F3002/W39F3002_sagas";
import W39F3015Sagas from "../redux/W3X/W39F3015/W39F3015_sagas";
import W39F3016Sagas from "../redux/W3X/W39F3016/W39F3016_sagas";
import W39F6000Sagas from "../redux/W3X/W39F6000/W39F6000_sagas";
import W39F3036Sagas from "../redux/W3X/W39F3036/W39F3036_sagas";

// W39
import W39F1030Sagas from "../redux/W3X/W39f1030/W39F1030_sagas";
import W25F6000Sagas from "../redux/W2X/W25F6000/W25F6000_sagas";
import W25F2303Sagas from "../redux/W2X/W25F2303/W25F2303_sagas";
import W09F2150Sagas from "../redux/W0X/W09F2150/W09F2150_sagas";
import W00F0005Sagas from "../redux/W0X/W00F0005/W00F0005_sagas";
import W39F3035Sagas from "../redux/W3X/W39F3035/W39F3035_sagas";
import W39F3040Sagas from "../redux/W3X/W39F3040/W39F3040_sagas";
import W39F3060Sagas from "../redux/W3X/W39F3060/W39F3060_sagas";

// W77
import W77F1010Sagas from "../redux/W7X/W77F1010/W77F1010_sagas";
import W77F1011Sagas from "../redux/W7X/W77F1011/W77F1011_sagas";
import W77F1013Sagas from "../redux/W7X/W77F1013/W77F1013_sagas";
import W25F2011Sagas from "../redux/W2X/W25F2011/W25F2011_sagas";
import W75F8003Sagas from "../redux/W75/W75F8003/W75F8003_sagas";

import W25F1000Sagas from "../redux/W2X/W25F1000/W25F1000_sagas";

// W84
import W84F3005Sagas from "../redux/W8X/W84F3005/W84F3005_sagas";
import W09F3000Sagas from "../redux/W0X/W09F3000/W09F3000_sagas";
import W09F3005Sagas from "../redux/W0X/W09F3005/W09F3005_sagas";
import W84F3010Sagas from "../redux/W8X/W84F3010/W84F3010_sagas";
// W98
import W89F1000Sagas from "../redux/W8X/W89F1000/W89F1000_sagas";

import W09F3010Sagas from "../redux/W0X/W09F3010/W09F3010_sagas";
import W09F2033Sagas from "../redux/W0X/W09F2033/W09F2033_sagas";
import W09F2090Sagas from "../redux/W0X/W09F2090/W09F2090_sagas";
import W09F2091Sagas from "../redux/W0X/W09F2091/W09F2091_sagas";

import { watchConfirmEmail } from "../redux/W2X/W25F2303/W25F2303_sagas";

import W00F1000Sagas from "../redux/W0X/W00F1000/W00F1000_sagas";
import W82F1000Sagas from "../redux/W8X/W82F1000/W82F1000_sagas";

export default function* rootSaga() {
    yield all(
        [
            ...new Set([
                watchLoading,
                watchUploadFile,
                watchLogin,
                watchGetTokenLogin,
                watchChangeThemes,
                watchUpdateFilters,
                watchGetThemes,
                watchRemoveDevice,
                watchAddDevice,
                watchUpdateBadge,
                watchGetBadge,
                watchListNoty,
                watchUpdateStatusNoty,
                watchListUser,
                watchCheckToken,
                watchGetListReminderMaster,
                watchGetListReminderDetails,
                watchIgnoreReminder,

                SettingSagas,

                FileSagas,

                //Home
                HomeSagas,

                //Admin
                AdminSagas,

                //Generals
                GeneralSagas,

                //User
                UserSagas,

                //Reports
                ReportsSagas,

                //Approvals
                ApprovalSagas,

                //History
                HistorySagas,


                //W09F2005
                // W09F2005Sagas,

                // W21F2010
                W21F2010Sagas,

                // W29F2010
                W29F2010Sagas,

                // W29F2012
                W29F2012Sagas,

                // W25F2023
                W25F2023Sagas,

                // W29F2306
                W29F2306Sagas,

                // W29F2040
                W29F2040Sagas,

                // W29F2045
                W29F2045Sagas,

                // W29F2005
                W29F2005Sagas,

                // W29F2060
                W29F2060Sagas,

                // W21F2005
                W21F2005Sagas,

                // W25F2000
                W25F2000Sagas,

                W25F2033Sagas,
                W25F2012Sagas,

                //W00F0010
                W00F0010Sagas,
            W00F1000Sagas,
                // watchGetCboDatabaseName()

                //W09
                W09F0001Sagas,
                W09F2000Sagas,
                W09F2005Sagas,
                W09F6000Sagas,
                W09F2060Sagas,
                W09F2071Sagas,
                W09F2070Sagas,
                W09F2080Sagas,
                W09F1501Sagas,
                W09F1500Sagas,
                W09F2010Sagas,
                W09F9000Sagas,
                W09F2020Sagas,
                W09F2021Sagas,
                W09F2023Sagas,
                W09F2150Sagas,

                W09F2152Sagas,
                W09F2031Sagas,
                W09F2081Sagas,
                W09F2092Sagas,
                W09F2030Sagas,
                W09F2032Sagas,
                W09F3010Sagas,
                W09F3000Sagas,
                W09F3005Sagas,
                W09F4000Sagas,
                W09F2205Sagas,
                W09F2001Sagas,
                W09F2205Sagas,
                W09F2210Sagas,
                W09F2200Sagas,
                W09F2215Sagas,
                W09F2230Sagas,
                W09F2033Sagas,
                W09F2281Sagas,

                W09F2215Sagas,
                W09F2225Sagas,
                W09F2270Sagas,
                W09F2280Sagas,
                W09F2290Sagas,
                W09F2291Sagas,
                W09F2292Sagas,
                W09F2090Sagas,
                W09F2091Sagas,
                W09F2310Sagas,
                W09F1120Sagas,
                W09F1121Sagas,
            //W13
            W13F1003Sagas,
                //W25
                W25F2002Sagas,
                W25F2010Sagas,
                W25F2015Sagas,
                W25F2018Sagas,
                W25F1000Sagas,
                W25F2011Sagas,
                W25F2041Sagas,
                W25F2040Sagas,
                W25F4000Sagas,
                //W29
                W29F2000Sagas,
                W29F2006Sagas,
                W29F3000Sagas,
                W29F2011Sagas,
                W29F2018Sagas,
                W29F2025Sagas,
                W29F2100Sagas,
                W29F2030Sagas,
                W29F2016Sagas,
                W29F2065Sagas,
                W29F2050Sagas,
                W29F2055Sagas,
                W29F2056Sagas,
                W29F2070Sagas,
                W29F2072Sagas,
                W29F2080Sagas,
                W29F2081Sagas,
                W29F2056Sagas,
                W29F2017Sagas,
                W29F2086Sagas,
                W29F2068Sagas,
                W29F2061Sagas,
                W29F2300Sagas,
                W29F2305Sagas,
            W29F2120Sagas,

                W29F2309Sagas,
                W29F2085Sagas,
                W29F2038Sagas,
                //W38
                W38F2000Sagas,
                W38F2001Sagas,
                W39F3001Sagas,

                //W39
                W39F1000Sagas,
                W39F1005Sagas,
                W39F3020Sagas,
                W39F3025Sagas,
                W39F3030Sagas,

                //W39
                W39F1010Sagas,
                W39F1012Sagas,
                //W39
                W39F2000Sagas,

                //W3X
                W39F1011Sagas,

                //W39
                W39F2001Sagas,
                W39F3000Sagas,
                W39F3005Sagas,
                W39F3010Sagas,
                W39F3011Sagas,
                W39F3002Sagas,
                W39F3015Sagas,
                W39F3016Sagas,
                W39F6000Sagas,

                W39F3000Sagas,
                W39F3035Sagas,
                W39F3036Sagas,
                W39F3040Sagas,
                W39F2035Sagas,
                W39F3060Sagas,

                //W71
                W77F1000Sagas,
                W77F1001Sagas,
                //W75F2000
                W75F2000Sagas,

                //W51F1001
                W51F1001Sagas,
                W51F1100Sagas,
                W51F1101Sagas,
            W51F2200Sagas,
            W51F2201Sagas,

                //W75F4090
                watchGetCombo,
                watchGetList,

                //W75F2005
                W75F2005Sagas,

                //W75F2008
                W75F2008Sagas,

                //W75F2010
                W75F2010Sagas,
                W75F8003Sagas,

                //W51F1002
                watchGetGridEquipments,

                //W39F1020
                W39F1020Sagas,

                //W39F1025
                W39F1025Sagas,

            //W82F1000
            W82F1000Sagas,

                //W39F1031
                W39F1031Sagas,

                //W39F1030
                W39F1030Sagas,

                //W25F2303
                W25F2303Sagas,

                // W77F1010
                W77F1010Sagas,

                // W77F1011
                W77F1011Sagas,

                //W77F1013
                W77F1013Sagas,

                //W25F6000
                W25F6000Sagas,

                //W00F00005
                W00F0005Sagas,

                // W84F3005
                W84F3005Sagas,
                //W00F00005
                W00F0005Sagas,

                // W98F1000
                W89F1000Sagas,

                //W25F2303
                watchConfirmEmail,

                // W84F3010
                W84F3010Sagas,
            ]),
        ]
            .map(i => i())
            .flat(1)
    );
}
