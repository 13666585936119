import {delay} from 'redux-saga';
import {put, takeLatest, take, cancel} from 'redux-saga/effects';
import Api from '../../services/api';
import {types} from "./user_actions";

export default function UserSagas() {
    return [
        watchChangePassword(),
        watchForgotPassword(),
        watchCheckSecurityCode(),
        watchResetPassword()
    ];
}

//##############################
export function* login(data, cb) {
    try {
        yield delay(300);
        if(data){
            yield put({type: types.LOGIN + '_SUCCESS', payload: data.params}); //đi qua lieu vao reducer
            data.cb && data.cb(null, data);
            return;
        }
        else{
            if(data.cb) data.cb(data, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchLogin() {
    while (true){
        const watcher = yield takeLatest(types.LOGIN,login);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//##############################
export function* getInfo(data, cb) {
    try {
        yield delay(300);
        if(data){
            data.cb && data.cb(null, data);
        }
        else{
            if(data.cb) data.cb(data, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchInfoUser() {
    while (true){
        const watcher = yield takeLatest(types.INFO_USER,getInfo);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//user change password
export function* changePassword(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/user/change-password', data.params);
        if (response && response.data) {
            yield put({ type: types.USER_CHANGE_PASSWORD + '_SUCCESS', payload: data.params });
            data.cb && data.cb(null, data);
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save user roles error');
    }
}

export function* watchChangePassword() {
    while (true){
        const watcher = yield takeLatest(types.USER_CHANGE_PASSWORD, changePassword);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//user forgot password
export function* forgotPassword(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/user/forgot', data.params);
        if (response && response.data) {
            yield put({ type: types.USER_FORGOT_PASSWORD + '_SUCCESS', payload: data.params });
            data.cb && data.cb(null, response.data);
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save user roles error');
    }
}

export function* watchForgotPassword() {
    while (true){
        const watcher = yield takeLatest(types.USER_FORGOT_PASSWORD, forgotPassword);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//check security code
export function* checkSecurityCode(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/user/check-security-code', data.params);
        if (response && response.data) {
            yield put({ type: types.CHECK_SECURITY_CODE + '_SUCCESS', payload: data.params });
            data.cb && data.cb(null, response.data);
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save user roles error');
    }
}

export function* watchCheckSecurityCode() {
    while (true){
        const watcher = yield takeLatest(types.CHECK_SECURITY_CODE, checkSecurityCode);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//user reset password
export function* resetPassword(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/user/reset', data.params);
        if (response && response.data) {
            yield put({ type: types.USER_RESET_PASSWORD + '_SUCCESS', payload: data.params });
            data.cb && data.cb(null, response.data);
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save user roles error');
    }
}

export function* watchResetPassword() {
    while (true){
        const watcher = yield takeLatest(types.USER_RESET_PASSWORD, resetPassword);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################